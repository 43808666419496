<template>
  <swiper
    id="slider-component"
    class="swiper-multiple px-4"
    :options="swiperOptions"
  >
    <!-- ANCHOR slides -->
    <swiper-slide
      v-for="(day, index) in workingDays"
      :key="index"
      class="slide"
    >
      <b-card
        no-body
        class="text-center text-dark mb-0 d-flex-center justify-content-start mr-0 px-0 h-100"
        :class="`${day === workDate ? 'btn bg-slider-active font-weight-bold'
          : 'btn font-weight-bold text-white'
        }`"
        @click="handleClickTab(day)"
      >
        <b-card-body class="p-0 d-flex flex-column justify-content-between">
          <div class="text-nowrap text-xl">
            {{ $t(getTextDay(day)) }}
          </div>
          <div class="text-nowrap">
            {{ day }}
          </div>
        </b-card-body>
      </b-card>
    </swiper-slide>

    <!-- ANCHOR Arrows -->
    <div
      slot="button-next"
      class="swiper-button-next btn-button"
    />
    <div
      slot="button-prev"
      class="swiper-button-prev btn-button"
    />
  </swiper>
</template>

<script>
import {
  ref, computed,
} from '@vue/composition-api'
import {
  BCard, BCardBody,
} from 'bootstrap-vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

import { getMoment, formatDateMonth, getTextDay } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BCardBody,
    Swiper,
    SwiperSlide,
  },

  computed: {
    workDate: {
      get() { return this.$store.getters['app-work-schedule/getWorkDate'] },
      set(newValue) { this.$store.dispatch('app-work-schedule/setWorkDate', newValue) },
    },

    employeeType: {
      get() { return this.$store.getters['app-work-schedule/getEmployeeType'] },
    },
  },

  setup(props, { emit }) {
    // swiper
    const swiperOptions = ref({
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      slidesPerView: 7,
      slidesPerGroup: 7,
      spaceBetween: 10,
      breakpoints: {
        1280: {
          slidesPerView: 7,
          slidesPerGroup: 7,
        },
        768: {
          slidesPerView: 3,
          slidesPerGroup: 3,
        },
        640: {
          slidesPerView: 2,
          slidesPerGroup: 2,
        },
        320: {
          slidesPerView: 1,
          slidesPerGroup: 1,
        },
      },
    })

    const workingDays = Array.from({ length: 14 }).map((_, index) => formatDateMonth(getMoment().add({ days: index }), 'YYYY-MM-DD'))

    const action = ref('')
    const refId = ref('')

    const emptyFilters = computed(() => !(action.value || refId.value))

    function handleClickTab(day) {
      this.$store.dispatch('app-work-schedule/setWorkDate', day)
      emit('refetch-data-with-filters')
    }

    return {
      action,
      refId,
      emptyFilters,

      workingDays,
      getTextDay,
      formatDateMonth,
      getMoment,
      swiperOptions,
      handleClickTab,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/swiper.scss';
@import '~swiper/css/swiper.css';

#slider-component ::v-deep {
  .swiper-slide {
    color: white;
    height: 64px;
  }

  .swiper-button-prev {
    &:after {
      background-image: url(str-replace(str-replace($chevron-left, 'currentColor', $primaryV2), '#', '%23')) !important;
    }
  }

  .swiper-button-next {
    right: 0;

    &:after {
      background-image: url(str-replace(str-replace($chevron-right, 'currentColor', $primaryV2), '#', '%23')) !important;
    }
  }

  .swiper-button-prev.btn-button,
  .swiper-button-next.btn-button {
    margin-top: 0;
    height: 70%;
    translate: 0 -50%;

    &:hover {
      background: rgba(#ccc, 0.3);
      backdrop-filter: blur(5px);
      border-radius: 8px;
    }
  }
}

.bg-slider-active {
  background-color: $primaryV2;
  color: white !important;
}

.text-xl {
  font-size: 20px;
}
</style>

<template>
  <div>
    <WorkScheduleFilter @refetch-data-with-filters="refetchData" />
    <b-card
      no-body
      class="mt-1"
    >
      <b-overlay
        :show="loading"
        rounded="sm"
        no-fade
        variant="light"
        :opacity="0.85"
      >
        <b-table
          ref="refScheduleListTable"
          style="max-height: 70vh"
          sticky-header
          class="position-relative"
          :items="fetchSchedules"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          :empty-text="$t('noRecordFund')"
          :sort-desc.sync="isSortDirDesc"
          :sort-by.sync="sortBy"
          no-border-collapse
        >
          <!-- COLUMNS HEADER -->
          <template
            v-for="column in tableColumns"
            #[`head(${column.key})`]="data"
          >
            <span
              :key="column.label"
              class="text-dark text-nowrap"
            >
              {{ $t(`schedule.${data.label}`) }}
            </span>
          </template>

          <template #cell(employee)="{ item }">
            <b-media vertical-align="center">
              <div class="d-flex align-items-center gap-1">
                <svg
                  v-if="!scheduleType"
                  xmlns="http://www.w3.org/2000/svg"
                  width="11"
                  height="11"
                  viewBox="0 0 11 11"
                  fill="none"
                >
                  <circle
                    cx="5.5"
                    cy="5.5"
                    r="5.5"
                    fill="#8FD00A"
                  />
                </svg>
                <svg
                  v-else
                  xmlns="http://www.w3.org/2000/svg"
                  width="11"
                  height="11"
                  viewBox="0 0 11 11"
                  fill="none"
                >
                  <circle
                    cx="5.5"
                    cy="5.5"
                    r="5.5"
                    fill="#999"
                  />
                </svg>
                <span class="text-xl text-nowrap">
                  {{ `${employeeType ? 'Sales' : 'Booker'} ${replaceEmployeeName(item.employee)}` }}
                </span>
                <span class="border-1" />
                <span
                  class="text-nowrap"
                  style="color: #333"
                >
                  {{ item.employee.lastName }} {{ item.employee.firstName }}
                </span>
              </div>
            </b-media>
          </template>

          <template #cell(scheduleCalendarTimes)="{ item }">
            <div class="d-flex flex-column gap-2">
              <div
                v-for="time of item.scheduleCalendars"
                :key="time.id"
                class="d-flex gap-1"
              >
                <b-button
                  v-for="(ele, index) of time.scheduleCalendarTimes"
                  :key="index"
                  variant="outline-dark"
                  size="sm"
                  style="font-size: 18px;"
                >
                  {{ ele.startTime }} - {{ ele.endTime }}
                </b-button>
              </div>
            </div>
          </template>

          <template #cell(type)="{ item }">
            <b-badge
              v-if="item.employee.type === 'BE' && item.employee.bookerType"
              class="badge-type"
              :variant="item.employee.bookerType === 'INLAND' ? 'info' : 'warning'"
            >
              {{ $te(`schedule.${item.employee.bookerType}`) ? $t(`schedule.${item.employee.bookerType}`): item.employee.bookerType }}
            </b-badge>
            <div
              v-else
              class="text-danger"
            >
              <div
                v-for="tag in item.scheduleTags"
                :key="tag.id"
              >
                {{ tag.content }}
              </div>
            </div>
          </template>

          <template #cell(employeePhoneNumber)="{ item }">
            <a
              :href="`https://zalo.me/${item.employee.phoneNumber}`"
              target="_blank"
              rel="no"
            >
              <h3 class="font-weight-bold text-info">
                <feather-icon
                  size="20"
                  icon="PhoneIcon"
                  class=""
                />
                {{ item.employee.phoneNumber || 'N/A' }}
              </h3>
            </a>
          </template>
        </b-table>
      </b-overlay>

      <div class="mx-2 mb-2">
        <b-row>
          <!-- Showing -->
          <b-col
            cols="12"
            sm="2"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted text-nowrap">{{ dataMeta.from }} {{ $t('paginationText.to') }} {{ dataMeta.to }}
              <span class="d-inline-block d-sm-none d-md-inline"> {{ $t('paginationText.outOf') }} </span>
              <span class="d-none d-sm-inline d-md-none"> / </span>
              {{ dataMeta.of }} {{ $t('paginationText.items') }}</span>
          </b-col>

          <!-- Pagination -->
          <b-col
            cols="12"
            sm="8"
            class="d-flex align-items-center justify-content-center"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalSchedules"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BTable,
  BOverlay,
  BCard,
  BMedia,
  BBadge,
  BRow,
  BCol,
  BPagination,
  BButton,
} from 'bootstrap-vue'

import {
  sizePerPageOptions,
  resolveGender,
} from '@/constants/selectOptions'

import {
  avatarText,
} from '@core/utils/filter'

import WorkScheduleFilter from './WorkScheduleFilter.vue'
import useScheduleList from './useScheduleList'
import useEmployeeHandle from '../../employee/useEmployeeHandle'

export default {
  components: {
    BTable,
    BOverlay,
    BCard,
    WorkScheduleFilter,
    BMedia,
    BBadge,
    BRow,
    BCol,
    BPagination,
    BButton,
  },

  setup() {
    const {
      fetchSchedules,
      tableColumns,
      perPage,
      currentPage,
      totalSchedules,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refScheduleListTable,
      refetchData,
      loading,
      searchTextFilter,
      employeeType,
      scheduleType,
    } = useScheduleList()

    const {
      resolveEmployeeTypeVariant,
      resolveEmployeeTypeIcon,
      resolveEmployeeTypeTitle,
      resolveEmployeeIsActiveVariant,
    } = useEmployeeHandle()

    function replaceEmployeeName(employee) {
      if (employee.scheduleCode) {
        if (/^[0-9]+$/.test(employee.scheduleCode)) return employee.scheduleCode >= 10 ? parseInt(employee.scheduleCode, 10).toString() : parseInt(employee.scheduleCode, 10).toString().padStart(2, '0')
        return employee.scheduleCode
      }
      if (employee.username.includes('booker')) {
        const result = employee.username.split('booker')[1]
        if (/^[0-9]+$/.test(result)) return result >= 10 ? parseInt(result, 10).toString() : parseInt(result, 10).toString().padStart(2, '0')
        return result
      }
      if (employee.username.includes('sales')) {
        const result = employee.username.split('sales')[1]
        if (/^[0-9]+$/.test(result)) return result >= 10 ? parseInt(result, 10).toString() : parseInt(result, 10).toString().padStart(2, '0')
        return result
      }
      return employee.username
    }

    return {

      fetchSchedules,
      tableColumns,
      perPage,
      currentPage,
      totalSchedules,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refScheduleListTable,
      refetchData,
      resolveGender,
      loading,

      employeeType,
      scheduleType,

      avatarText,
      resolveEmployeeTypeVariant,
      resolveEmployeeTypeIcon,
      resolveEmployeeTypeTitle,
      resolveEmployeeIsActiveVariant,

      searchTextFilter,

      sizePerPageOptions,
      replaceEmployeeName,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.badge-type {
  min-width: 120px;
  padding: 8px 12px;
}

.badge-inland {
  background-color: #3184B2;
}

.badge-international {
  background-color: #efad02;
}

.text-xl {
  font-size: 20px;
}

.border-1 {
  width: 1px;
  height: 22px;
  background: #000;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('swiper', {
    staticClass: "swiper-multiple px-4",
    attrs: {
      "id": "slider-component",
      "options": _vm.swiperOptions
    }
  }, [_vm._l(_vm.workingDays, function (day, index) {
    return _c('swiper-slide', {
      key: index,
      staticClass: "slide"
    }, [_c('b-card', {
      staticClass: "text-center text-dark mb-0 d-flex-center justify-content-start mr-0 px-0 h-100",
      class: "".concat(day === _vm.workDate ? 'btn bg-slider-active font-weight-bold' : 'btn font-weight-bold text-white'),
      attrs: {
        "no-body": ""
      },
      on: {
        "click": function click($event) {
          return _vm.handleClickTab(day);
        }
      }
    }, [_c('b-card-body', {
      staticClass: "p-0 d-flex flex-column justify-content-between"
    }, [_c('div', {
      staticClass: "text-nowrap text-xl"
    }, [_vm._v(" " + _vm._s(_vm.$t(_vm.getTextDay(day))) + " ")]), _c('div', {
      staticClass: "text-nowrap"
    }, [_vm._v(" " + _vm._s(day) + " ")])])], 1)], 1);
  }), _c('div', {
    staticClass: "swiper-button-next btn-button",
    attrs: {
      "slot": "button-next"
    },
    slot: "button-next"
  }), _c('div', {
    staticClass: "swiper-button-prev btn-button",
    attrs: {
      "slot": "button-prev"
    },
    slot: "button-prev"
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <b-row>
      <b-col sm="6">
        <div class="bg-white">
          <b-tabs
            v-model="employeeType"
            fill
            pills
            nav-class="p-1"
            active-nav-item-class="tab-active border-0"
          >
            <b-tab
              v-for="(feature, key) in EMPLOYEE_TYPES"
              :key="key"
            >
              <template #title>
                <span>
                  {{ key.toUpperCase() }}
                </span>
              </template>
            </b-tab>
          </b-tabs>
        </div>
      </b-col>
      <b-col sm="6">
        <div class="bg-white">
          <b-tabs
            v-model="scheduleType"
            fill
            pills
            nav-class="p-1"
            active-nav-item-class="tab-active border-0"
          >
            <b-tab :disabled="!isToday">
              <template #title>
                <span>
                  {{ $t('schedule.currentSchedule').toUpperCase() }}
                </span>
              </template>
            </b-tab>
            <b-tab>
              <template #title>
                <span>
                  {{ $t('schedule.nextSchedule').toUpperCase() }}
                </span>
              </template>
            </b-tab>
          </b-tabs>
        </div>
      </b-col>
    </b-row>
    <WorkScheduleList />
  </div>
</template>

<script>
import moment from 'moment'
import {
  BTab, BTabs, BCol, BRow,
} from 'bootstrap-vue'
import { onUnmounted, computed } from '@vue/composition-api'

import store from '@/store'
import {
  WORK_SCHEDULE_APP_STORE_MODULE_NAME,
  EMPLOYEE_TYPES,
} from '@/constants/schedule'

import WorkScheduleList from './WorkScheduleList.vue'
import WorkScheduleStoreModule from './WorkScheduleStoreModule'

export default {
  components: {
    BCol,
    BRow,
    BTab,
    BTabs,
    WorkScheduleList,
  },
  computed: {
    employeeType: {
      get() {
        return this.$store.getters[`${WORK_SCHEDULE_APP_STORE_MODULE_NAME}/getEmployeeType`]
      },
      set(val) {
        this.$store.dispatch(`${WORK_SCHEDULE_APP_STORE_MODULE_NAME}/setEmployeeType`, val)
      },
    },
    scheduleType: {
      get() {
        return this.$store.getters[`${WORK_SCHEDULE_APP_STORE_MODULE_NAME}/getScheduleType`]
      },
      set(val) {
        this.$store.dispatch(`${WORK_SCHEDULE_APP_STORE_MODULE_NAME}/setScheduleType`, val)
      },
    },
  },
  setup() {
    // Register module
    if (!store.hasModule(WORK_SCHEDULE_APP_STORE_MODULE_NAME)) store.registerModule(WORK_SCHEDULE_APP_STORE_MODULE_NAME, WorkScheduleStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(WORK_SCHEDULE_APP_STORE_MODULE_NAME)) store.unregisterModule(WORK_SCHEDULE_APP_STORE_MODULE_NAME)
    })

    const isToday = computed(() => store.getters[`${WORK_SCHEDULE_APP_STORE_MODULE_NAME}/getWorkDate`] === moment().format('YYYY-MM-DD'))

    return {
      EMPLOYEE_TYPES,
      isToday,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.tab-active {
  background-color: $primaryV2 !important;
}
</style>
